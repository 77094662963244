<template>
  <div id="imprint-index">
    <Headline />
    <ImprintIndex />
  </div>
</template>

<script>
import Headline from '../../components/app/Headline'
import ImprintIndex from '../../components/imprint/ImprintIndex'

export default {
  components: {
    Headline,
    ImprintIndex
  }
}
</script>
