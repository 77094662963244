<template>
  <div>
    <div class="mb-10">
      <p><strong>Team Internet AG</strong></p>
      <p>Liebherrstr. 22</p>
      <p>80538 Munich, Germany</p>
      <p><a href="mailto:support@parkingcrew.com">support@parkingcrew.com</a></p>
    </div>
    <div class="mb-10">
      <p>Phone: 089 / 416 146 030</p>
      <p>Fax: 089 / 416 146 090</p>
    </div>
    <div class="mb-10">
      <p>Board of Management: Gaëlle Lallement, Axel Kaltz, Andreas Lunz</p>
      <p>Chairman of the Supervisory Board: Michael Riedl</p>
      <p>Domicilie and Court of Registry: Munich</p>
      <p>Commercial Register No.: HRB 200081</p>
      <p>VAT: DE270418747</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImprintIndex'
}
</script>
